import hljs from 'highlight.js';
import '../styles/main.scss';
const ready = (callback) => {
    if (document.readyState != "loading") callback();
    else document.addEventListener("DOMContentLoaded", callback);
}

ready(async () => {
    hljs.initHighlightingOnLoad();
});
